import React from "react"
import { cn } from "@/utils/helpers"
import useThemeOptions from "@/hooks/useThemeOptions"
import { ButtonLink } from "./reusable/Button"
import PreviewImage from "./reusable/PreviewImage"

const Page404 = () => {
  const title = useThemeOptions("fofTitle")
  const content = useThemeOptions("fofContent")
  const buttons = useThemeOptions("fofButtons")
  const image = useThemeOptions("fofImage")
  const edges = useThemeOptions("buttonEdges")
  return (
    <div
      className={cn(
        "page-404 wrapper mx-auto flex w-full max-w-wrapper flex-wrap items-center justify-center py-10 md:flex-row-reverse"
      )}
    >
      <div className="image basis-full p-6 text-center md:basis-1/2 lg:basis-3/5">
        <PreviewImage
          image={image.localFile}
          fallbackUrl={image.sourceUrl}
          className={`mx-auto block`}
        />
      </div>
      <div className="content basis-full py-20 md:basis-1/2 lg:basis-2/5">
        <h1
          className={`bd-core-heading-block relative mx-auto mb-12 mb-4 block w-full max-w-wrapper px-6 font-header text-5xl font-normal leading-[1.16] text-theme-black lg:text-[68px] lg:leading-[1.05]`}
        >
          {title}
        </h1>
        <div
          className="bd-core-paragraph-block relative mx-auto mb-12 block flex w-full max-w-wrapper flex-col gap-12 px-6 font-body text-theme-black [&_a]:text-theme-accent [&_a]:underline [&_a]:transition-colors [&_a]:hover:text-theme-accent/80 [&_a]:focus:text-theme-accent/80"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className={`flex flex-wrap gap-8 px-6`}>
          {buttons.map((button, index) => {
            return (
              <ButtonLink
                key={index}
                link={button.button.url}
                variant="solid"
                edges={edges}
                color="primary"
              >
                {button.button.title}
              </ButtonLink>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Page404
